import React from "react"

import Layout from "../components/organisms/layout"
import SEO from "../components/seo"
import PageBackground from "../components/atoms/page-background"
import EMYSPage from "../components/organisms/emys-page"
import { graphql } from "gatsby"

const EMYSPageWrapper = ({ data }) => (
  <Layout
    cert1={data.cert1.childImageSharp.fluid}
    cert2={data.cert2.childImageSharp.fluid}
  >
    <SEO title="EMYS" />

    <EMYSPage
      emysHello={data.emysHello.childImageSharp.fluid}
      emysLogo={data.emysLogo.childImageSharp.fluid}
    />
  </Layout>
)

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "emys-long.png" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert1: file(relativePath: { eq: "cambridge-english-assessment.png" }) {
      childImageSharp {
        fluid(maxWidth: 384, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert2: file(relativePath: { eq: "direct-method.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 244, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    emysHello: file(relativePath: { eq: "emys-hello.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    emysLogo: file(relativePath: { eq: "emys-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default EMYSPageWrapper
