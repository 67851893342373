import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import ParagraphItalic from "../atoms/paragraph-italic"
import Heding from "../atoms/newsletter-heading"
import Wrapper from "../atoms/page-wrapper"

const Conatiner = styled.article`
  width: 80%;
  margin-top: 2vh;
`

const Heading = styled.h3`
  margin-bottom: 2vh;
`

const Paragraph = styled.p`
  font-size: 1.6rem;
  color: #000;
  margin-top: 4vh;
`

const StyledImg = styled(Img)`
  margin: 0;
  height: 400px !important;
  width: auto !important;

  img {
    object-fit: contain !important;
  }

  @media (max-width: 980px) {
    height: 300px !important;
  }
`

const StyledImg2 = styled(Img)`
  margin: 0;
  height: 100px !important;
  width: auto !important;

  margin-bottom: 4vh;

  img {
    object-fit: contain !important;
  }
`

const EmysPage = ({ emysHello, emysLogo }) => (
  <Wrapper noImg>
    <StyledImg2 fluid={emysLogo} />

    <ParagraphItalic>
      baw się, ucz, kochaj... z przyjaznym native speakerem!
    </ParagraphItalic>
    <Heding>EMYS</Heding>

    <Conatiner>
      <Paragraph>
        EMYS najbardziej zaawansowany anglojęzyczny robot edukacyjny o
        przyjaznym wyglądzie i wyjątkowych możliwościach interaktywnych dla
        dzieci w wieku 3 - 7 lat.
      </Paragraph>
      <StyledImg fluid={emysHello} />
      <Heading>O EMYSIE</Heading>
      <Paragraph>
        Dzieci w wieku 3 - 7 lat kochają EMYSa - najbardziej zaawansowanego,
        anglojęzycznego robota edukacyjnego na świecie o przyjaznym wyglądzie i
        wyjątkowych interaktywnych możliwościach. EMYS jest bogatym źródłem
        odpowiednio dobranego zakresu języka angielskiego prezentowanego przez
        serię gier, zabaw, projektów kreatywnych, piosenek i historyjek. Robot
        reaguje na dotyk dzieci. Jest wyposażony w różnorodne i wysokiej jakości
        interaktywnych akcesoriów, które zostały stworzone, by stymulować
        wszystkie dziecięce zmysły. Wreszcie ten wyjątkowy angielski native
        speaker buduje z dziećmi więź emocjonalną. Regularnie prosząc o opiekę,
        uczy dzieci odpowiedzialności. Od R2D2 i C3PO przez WALL-E i Hala, to co
        kiedyś było marzeniem na ekranach kin, teraz staje się rzeczywistością
        zarówno dla dzieci, jak i dorosłych.
      </Paragraph>
    </Conatiner>
  </Wrapper>
)

export default EmysPage
